import React from 'react';
import { connect } from 'react-redux';
import { loadData } from '../../reducers/shape.js';
import Loader from 'cccisd-loader';

const mapStateToProps = (state, props) => ({
    groupName: state.app.shape.groupName,
    startDate: state.app.shape.startDate,
    endDate: state.app.shape.endDate,
});

export default connect(
    mapStateToProps,
    { loadData }
)(
    class ScreeningAndAssessment extends React.Component {
        state = {
            loading: true,
        };

        componentDidMount = async () => {
            await this.props.loadData();
            this.setState({ loading: false });
        };

        render() {
            if (this.state.loading) {
                return <Loader loading />;
            }

            return (
                <div>
                    <h1>Screening & Assessment</h1>
                </div>
            );
        }
    }
);
