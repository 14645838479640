import React from 'react';
import { ProfileUpdateForm } from 'cccisd-laravel-nexus';
import notification from 'cccisd-notification';
const Fortress = window.cccisd.fortress;

const UpdateProfile = () => {
    let showTheseProfileDataFields = {
        stateAdmin: ['position'],
        districtAdmin: ['position'],
        schoolAdmin: ['position'],
        stateTeamMember: ['position'],
        districtTeamMember: ['position'],
        schoolTeamMember: ['position'],
        shapeUser: ['position'],
    };
    return (
        <div style={{ maxWidth: '550px', margin: '0 auto' }}>
            <h1>Update Profile</h1>
            <ProfileUpdateForm
                onSuccess={() => {
                    notification('Profile has been updated');
                }}
                pawnId={Fortress.user.pawnId}
                pawnHash={Fortress.user.actual.random_hash}
                includeUserFields
                includePawnFields
                includePasswordFields={false}
                showTheseProfileDataFields={showTheseProfileDataFields}
            />
        </div>
    );
};

export default UpdateProfile;
